.contact-div{
    height: 800px;
    color:rgb(68, 63, 63);
    margin: 0 auto;
    /* border:3px solid black; */
    max-width: 1440px;
}

.input-fields{
    height:80px;
}

.form-control{
height:45px;
width: 65%;

}

.message-control{
height:200px;
width:65%;
}

.submit-field{
    margin-top: 20px;
}
.submit-control{
    background-color: #008CBA;
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    box-shadow:5px 5px 5px grey ;
    transition: 0s .5s;
    margin-bottom: 20px;
  }

  .submit-control:active{
    background-color: #0c3341;
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    box-shadow:5px 5px 5px grey ;
    transition: 0s;
  }
  .contact-title{
      font-size: 50px;
      margin:0;
      color: black;
  }