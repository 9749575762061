
.wave-div
{
  height: 450px;
  width:100%; 
  padding:3px; 
    background-size: 100%;
    background-image: url("../assests/waveBW.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
