.project-div{
    height:2100px;
    max-width: 100%;
    margin:0 auto;
    /* border:3px solid black; */
}
.project-title{
    font-size: 50px;
    margin:0;
}
.flex-box{
    max-width: 95%;
    margin-top: 50px;
    margin: auto;
    display: grid;
    justify-items: center;
    column-gap: 5%;
    row-gap: 5%;
    
    padding-top:3%;

}

.project1{
    width: 100%;
    height: 400px;
    border:3px solid black;
}
.flex-outter{

    margin-top: 50px;
}

.project-link:visited{
    text-decoration: none;
    color:black
}

.project-link{
    text-decoration: none;
}