.skills-div{
    height: 200px;
}
.skills-title{
    font-size: 50px;
    margin:0;
    color:black;
}
.skills{
    font-size: 30px;
    width: 300px;
    height:50px;
    text-align: center;
    
    align-self: center;
    background-color: #008CBA;
    box-shadow:5px 5px 5px grey ;

}
.skills-box{
    height: 150px;
    
   display: flex;
   justify-content: space-evenly;
   flex-wrap: nowrap;
   margin: auto;
   margin-top: 50px;
   /* border:3px solid black; */
   
}

.skills-link:visited{
    text-decoration: none;
    color: white;
}

.skills-link{
    text-decoration: none;
}