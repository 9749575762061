.card2{
    width:90%;
    height:700px;
    background:#333;
    color: #fff;
    margin: 0 20 px;
    /* display:flex;
    justify-content: center;
    align-items: center; */
}

.meat{
    width:80%;
    height:600px;
    background:#333;
    color: #fff;
    margin: auto;
    display:flex;
    justify-content: center;
    align-items: center;
}