.about-title{
    
font-size: 50px;
margin:0;

}

.inside-wave{
    height: 550px;
    width:100%; 
    padding:3px; 
      background-size: 100%;
      background-image: url("../assests/Ocean.jpg");
      background-repeat: no-repeat;
      background-size: cover; 


}

.download-button{
    background-color: #008CBA;
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    box-shadow:5px 5px 5px grey ;
    transition: 0s .5s;
    margin-bottom: 20px;
  }

  .download-button:active{
    background-color: #0c3341;
    border: none;
    color: white;
    padding: 15px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    box-shadow:5px 5px 5px grey ;
    transition: 0s;
  }