.title-div{
    color:rgb(68, 63, 63);
    margin: 0 auto;
    background-image: url("../assests/SanDiego2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    height:550px;

}


.jeff{
    text-align:  center;
    padding:25px;
    /* border:3px solid black; */
    margin:0 auto;
    font-size:5em;
    color:black;
}

.header-labels{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.header-topics{
    width:200px;
    height:100px;
    /* border:3px solid black; */
    font-size: 1.5em;
    background-color: rgb(208, 208, 212);
}